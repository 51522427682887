import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from "@sentry/react";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { FronteggProvider } from '@frontegg/react';

const contextOptions = {
  baseUrl: 'https://auth.insightmind.ai',
  clientId: 'b7466d71-f266-4f1c-ae4e-3eadd5aebeb0',
  appId: 'e68921fc-7283-41bb-a6a0-f9db24e8427f'
};

Sentry.init({
  dsn: "https://6751f5455fc03a8ee624586bf8bf7ad8@o4506241962147840.ingest.us.sentry.io/4508330481942528",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/api\.insightmind\.ai\/v1/],
  // Session Replay
  replaysSessionSampleRate: 0.5, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <FronteggProvider contextOptions={contextOptions}>
            <App/>
        </FronteggProvider>
    </React.StrictMode>
);

reportWebVitals();