import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '@frontegg/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldCat } from '@fortawesome/free-solid-svg-icons';  // You can use other icons, like faRocket for space 🚀

const Dashboard = ({ dashboardName }) => {
  const [metabaseUrl, setMetabaseUrl] = useState('');
  const [error, setError] = useState(false); // Track if there's an error fetching the URL
  const { user } = useAuth(); // Use the Frontegg hook to access user data
  const metabaseBaseUrl = process.env.REACT_APP_BACKEND_BASE_URL;

  useEffect(() => {
    const fetchMetabaseUrl = async () => {
      try {
        const response = await fetch(`${metabaseBaseUrl}access-dashboard?dashboardName=${dashboardName}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.accessToken}`, // Add the Authorization header
          },
        });

        if (response.ok) {
          const data = await response.json();
          setMetabaseUrl(data.url);
          setError(false); // Reset error if URL is fetched successfully
        } else {
          throw new Error('Failed to fetch the URL');
        }
      } catch (error) {
        console.error('Error fetching the Metabase URL', error);
        setError(true); // Set error state when the fetch fails
      }
    };

    if (user && dashboardName) {
      fetchMetabaseUrl(); // Fetch URL when the component is rendered
    }
  }, [user, dashboardName, metabaseBaseUrl]); // Dependency on user and dashboardName

  if (error) {
    // Display an error message with an icon if there's an issue fetching the URL
    return (
      <div className="text-center p-10">
        <FontAwesomeIcon icon={faShieldCat} size="4x" className="text-gray-500 mb-4" />
        <h1 className="text-3xl font-bold">404 Oops!</h1>
        <p className="mt-4 text-lg text-gray-700">The {dashboardName.charAt(0).toUpperCase() + dashboardName.slice(1)} dashboard is not available at this time.</p>
        <p className="mt-2 text-md text-gray-500">Please check the URL or try again later.</p>
      </div>
    );
  }

  if (!metabaseUrl) {
    return <div>Loading...</div>; // Show loading state while fetching data
  }

  // Dynamically set the title based on the dashboard name
  const title = `${dashboardName.charAt(0).toUpperCase() + dashboardName.slice(1)} Dashboard`;

  return (
    <div className="w-full h-full">
      <iframe
        src={metabaseUrl}
        title={title}
        width="100%"
        height="100%"
        className="w-full h-full"
        style={{ border: 'none' }}
      />
    </div>
  );
};

const DashboardWrapper = () => {
  const location = useLocation();

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/ltv" replace />} />
      <Route path="/ltv" element={<Dashboard dashboardName="ltv" />} />
      <Route path="/churn" element={<Dashboard dashboardName="churn" />} />
      <Route path="/vip" element={<Dashboard dashboardName="vip" />} />
      <Route path="/spv" element={<Dashboard dashboardName="spv" />} />
      <Route path="/heartbeat" element={<Dashboard dashboardName="heartbeat" />} />
    </Routes>
  );
};

export default DashboardWrapper;